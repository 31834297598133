import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { LOGO_URL } from "config";

import { LoginSharp, LogoutSharp } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "features/store";

import { useLogoutMutation } from "features/auth/auth";

const Header = () => {
  const { user, isAuthenticated } = useAppSelector(state => state.auth);
  const [ logout ] = useLogoutMutation();

  const onLogout = () => {
    logout();
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <img src={LOGO_URL} alt="Website" />
          </Link>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            flexGrow={1}
          >
            {isAuthenticated && user !== null ? (
              <>
                <Typography color="inherit" noWrap>
                  Hi, {user.username}
                  {/* Hi, {user.username} @ {user.customer?.short_name} */}
                </Typography>

                <Button
                  startIcon={<LogoutSharp />}
                  color="inherit"
                  onClick={onLogout}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button
                  startIcon={<LoginSharp />}
                  color="inherit"
                  href="/login"
                >
                  Login
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
