import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./containers/HomePage";
import LoginPage from "./containers/LoginPage";
import { CssBaseline } from "@mui/material";
import { useCheckAuthMutation, useGetUserQuery } from "features/auth/auth";
import { useEffect } from "react";

function App() {
  const [checkAuth] = useCheckAuthMutation();
  useGetUserQuery();
  useEffect(() => {
    checkAuth();
}, [])  

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
