import Box from "@mui/system/Box";
import { Typography, Link } from "@mui/material";
import { DISCLAIMER_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "config";

export default function Footer() {
  return (
    <Box
      component="footer"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      bgcolor={"lightgray"}
      padding={1}
    >
      <Typography variant="body2" color="text.primary" marginRight={1}>
        © {new Date().getFullYear()} All rights reserved.
      </Typography>
      <Link
        href={PRIVACY_POLICY_URL}
      >
        Privacy Policy
      </Link>
      <Link
        href={TERMS_OF_SERVICE_URL}
      >
        Terms of Use
      </Link>
      <Link
        href={DISCLAIMER_URL}
      >
        Disclaimer
      </Link>
    </Box>
  );
}
