import {
  Typography,
  Box,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLoginMutation } from "features/auth/auth";

type LoginInputs = {
  username: string;
  password: string;
};

export default function Login() {
  const [login, { isLoading }] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    console.log(data);
    login(data);
  };

  return (
    <Box
      marginTop={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography component="h1" variant="h4">
        Sign in{" "}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        marginTop={1}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          autoComplete="username"
          autoFocus
          {...register("username")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          {...register("password")}
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        )}
      </Box>
    </Box>
  );
}
