import Layout from "../components/Layout";
import { Container, Typography } from "@mui/material";

export default function HomePage() {
  return (
    <Layout title="Home" content="Home page">
      <Container component="main" maxWidth="xs" sx={{ mt: 12 }}>
        <Typography variant="h4">Welcome to Website</Typography>
      </Container>
    </Layout>
  );
}
