import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { theme } from "./hoc/Theme";
import { usePageTitle } from "./hoc/Hooks";
import Footer from "./Footer";
import Header from "./Header";
import { APP_NAME } from "config";

type LayoutProps = {
  title?: string;
  content?: string;
};

const Layout = ({
  title,
  content,
  children,
}: React.PropsWithChildren<LayoutProps>) => {
  usePageTitle(`${APP_NAME} | ` + (title || ""));
  return (
    <ThemeProvider theme={theme}>
      <Box display={"flex"} flexDirection={"column"} minHeight={"100vh"}>
        <Header />

        <Box component="main" flexGrow={1}>
          {children}
        </Box>

        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
